import React from 'react'
import social from '../../data/social.json'
import Icons from '../../images/social.svg'

const Contact = () => {
  return (
    <section className="contact">
      <div className="contact__container">
        <h2 className="contact__title wow animated fadeIn" data-wow-delay=".5s">
          Have an idea? Let&apos;s chat!
        </h2>
        <p className="contact__desc wow animated fadeIn" data-wow-delay=".5s">
          I&apos;m always looking forward to collaborations,
        </p>
        <p className="contact__desc wow animated fadeIn" data-wow-delay=".5s">
          so feel free to contact me at <a href="mailto:barbara@brina.mx">barbara@brina.mx</a>
        </p>
        <div className="contact__social">
          {social.map((item) => (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              key={item.icon}
              className="social__icon wow animated fadeIn"
              data-wow-delay={item.contactDelay}
            >
              <svg viewBox="0 0 45 45" className="contact__social-icon">
                <use xlinkHref={`${Icons}#${item.icon}`} />
              </svg>
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Contact
