import React from 'react'
import Item, { ProjectItem } from './Item'
import projects from '../../data/projects.json'

const Projects = () => {
  return (
    <section className="projects" id="projects">
      <div className="projects__container">
        {projects.map((item: ProjectItem) => (
          <Item key={item.imgName} {...item} />
        ))}
      </div>
    </section>
  )
}

export default Projects
