import React, { useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import social from '../../data/social.json'

const Header = () => {
  const [isMenuOpen, setMenuState] = useState(false)
  const toggleMenu = () => {
    setMenuState((prevState) => !prevState)
  }

  return (
    <header className={`header header--${isMenuOpen ? 'open' : 'closed'}`}>
      <div className="header__container">
        <h2 className="header__logo wow animated fadeIn" data-wow-delay=".1s">
          <span className="header__logo--strong">Bárbara</span> Brina
        </h2>
        <button type="button" onClick={toggleMenu} className="header__menu">
          <span className="header__menu-bar header__menu-bar--1" />
          <span className="header__menu-bar header__menu-bar--2" />
          <span className="header__menu-bar header__menu-bar--3" />
        </button>
        <nav className="header__nav">
          <AnchorLink href="#about" className="wow animated fadeIn" data-wow-delay=".3s">
            About Me
          </AnchorLink>
          <AnchorLink href="#projects" className="wow animated fadeIn" data-wow-delay=".4s">
            Projects
          </AnchorLink>
          {social.map((item) => (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              key={item.icon}
              className="wow animated fadeIn"
              data-wow-delay={item.headerDelay}
            >
              {item.name}
            </a>
          ))}
        </nav>
      </div>
    </header>
  )
}

export default Header
