import React from 'react'

const About = () => {
  return (
    <section className="about" id="about">
      <div className="about__container">
        <h2 className="about__title wow animated fadeIn" data-wow-delay=".2s">
          About <br /> Me.
        </h2>
        <div className="about__desc wow animated fadeIn" data-wow-delay=".5s">
          <p className="about__text">
            My passion is developing <b>products and experiences</b> that help make people&apos;s
            lives <i>easier.</i>
          </p>
          <div className="about__sep" />
          <p className="about__text">
            I&apos;m a frontend developer, with a BS in Computer Science, currently based in the
            beautiful country of Mexico
            <span role="img" aria-label="Mexican flag">
              🇲🇽
            </span>
            .
          </p>
          <p className="about__text">
            Some of my experience includes making responsive and mobile first websites, building
            scalable applications, A/B testing and conversion, rate and optimization.
          </p>
        </div>
      </div>
    </section>
  )
}

export default About
