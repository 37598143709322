import React from 'react'

export interface ProjectItem {
  title: string
  type: string
  imgName: string
  url: string
  extendedClass: string
  delay: string
}

const Item = ({ title, type, imgName, url, extendedClass, delay }: ProjectItem) => {
  return (
    <div
      className={`projects__item projects__item--${extendedClass} wow animated fadeIn`}
      data-wow-delay={delay}
    >
      <div className={`projects__item-img projects__item-img--${imgName}`} />
      <a href={url} className="projects__item-link" target="_blank" rel="noopener noreferrer">
        <h3 className="projects__item-title">{title}</h3>
        <p className="projects__item-type">{type}</p>
      </a>
      <div className="projects__item-border projects__item-border--top" />
      <div className="projects__item-border projects__item-border--bottom" />
      <div className="projects__item-border projects__item-border--left" />
      <div className="projects__item-border projects__item-border--right" />
    </div>
  )
}

export default Item
