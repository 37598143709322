import React from 'react'

const Footer = () => {
  const year = new Date().getFullYear()

  return (
    <footer className="footer">
      <div className="footer__container">
        <h2 className="footer__logo">
          <strong>Bárbara</strong> Brina © {year}
        </h2>
      </div>
    </footer>
  )
}

export default Footer
