import React from 'react'

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero__container">
        <h1 className="hero__title wow animated fadeIn" data-wow-delay=".2s">
          Frontend <span className="hero__title-caption">Developer</span>
        </h1>
      </div>
    </section>
  )
}

export default Hero
